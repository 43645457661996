import { Router } from '@angular/router';
import { GlobalService } from "app/global.service";
import * as moment from "moment";
import { SuccesSnakBarComponent } from "../../../components/succes-snak-bar/succes-snak-bar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

//import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

import {
    Component,
    Inject,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewChild,
    HostListener,
} from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    UntypedFormControl,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { MatColors } from "@fuse/mat-colors";
import { CalendarService } from "../calendar.service";
import { TeamService } from "@fuse/services/team.service";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil, take, map, timeout } from "rxjs";
import { EventActionService } from "../event-action.service";
import { ReloadDialogService } from "@fuse/services/reload-dialog.service";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { ExamResultComponent } from "../exam-result/exam-result.component";
import { ExamConduiteResultComponent } from "../exam-conduite-result/exam-conduite-result.component";
import { ErreurService } from "app/main/authentification/erreur.service";
import { SchedulingSettingComponent } from 'app/main/pop-ups/scheduling-setting/scheduling-setting.component';
import { EventFlowService } from '../event-flow.service';
import { EventLoadingService } from '../event-loading.service';

@Component({
    selector: "app-event-form",
    templateUrl: "./event-form.component.html",
    styleUrls: ["./event-form.component.scss"],
})
export class EventFormComponent implements OnInit, AfterViewInit, OnDestroy {
    SessionArray = [];
    SessionError;
    errorTimeout;

    deletable = true;

    isLoading = false;
    eventIsLoading = false;

    comp = false;
    // ? Boolean For Event Type
  //  datesSelected: NgbDateStruct[] = [];
    errorsArray = [];
    hasFrai: boolean = true;
    minutesGap: any;
    mindebut: any;
    maxdebut: any;
    minfin: any;
    maxfin: any;
    innerWidth: number;
    codeEx = false;
    codeExEventId: number;
    direct = false;

    dialogRef: any;
    dialogRefSettings: any;

    fraiModifier = false;
    fraiAuDepart: number;
    differenceFrai = 0;

    renouvModifier = false;
    renouvAuDepart: number;
    differenceRenouv = 0;

    completed = false;
    conduiteEx = false;
    errors = false;
    multiErrors = false;
    action: any;
    type: any;
    event: any;
    eventForm: UntypedFormGroup;
    presetColors = MatColors.presets;
    agents = [];
    cands = [];
    vehicules = [];
    title: any;
    fraiExam: number;
    canEdit = true;
    currAgent: any;
    conflit: string = "";

    openFrai = false;
    openRenouv = false;

    addingEvent = false;
    savingEvent = false;

    dateTouched = false;

    //follow result and confirmation update
    updated = null;

    //format 
    newFormat: boolean;
    disable_cond_type = false;
    minDate = null;

   // minim = new Date("2022-07-13T08:30:00+01:00") //.setHours(0,0,0,0) 
    maxDate = null;
    jourJ = "01/01/2023"
    jourJMinusOne = "12/31/2022"


    fullDay=false;

    changedTypeFromCircToCond = false;

    tel1:number;
    editingDuration = false;

    candidatSelected = false; // this varible used to check if candidat selected we need to filter the centers by the category of the candodat

    filtred_center = []; // filtred center by the candidat category
    

    allow_delete = true; //
    //const currentYear = moment().year();
/*  this.minDate = moment([currentYear - 1, 0, 1]);
 this.maxDate = moment([currentYear + 1, 11, 31]); */

    /* @HostListener('window:popstate', ['$event'])
    dismissDialog() {
        console.log('POPSTATE FORM',);
        this.closeThis()
    } */

    /**
     * Constructor
     *
     * @param {MatDialogRef<CalendarEventFormDialogComponent>} matDialogRef
     * @param _data
     */


    constructor(
        private _matDialog: MatDialog,
        private _reloadService: ReloadDialogService,
        private eventAction: EventActionService,
        private globalService: GlobalService,
        private errService: ErreurService,
        private snackBar: MatSnackBar,
        public fb: UntypedFormBuilder,
        public _matDialogRef: MatDialogRef<EventFormComponent>,
        public agentsService: TeamService,
        private _calendarService: CalendarService,
        private loadingService: EventLoadingService,
        private router : Router,
        private _eventFlowService: EventFlowService,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) { 
    }

    ngOnInit() {
        //?push fake state to navigate to when button back is clicked
        const modalState = {
            modal : true,
            desc : 'fake state for our modal'
        };
        history.pushState(modalState, null);

       
        //? test if there is any available
        if (this._data.available) {
            moment.locale("fr");

            //
            this._calendarService.getAgence().subscribe({
                next:(res) => {
                    this.mindebut = res.heureOuverture;
                    this.maxdebut = moment(res.heureFermeture, "HH:mm")
                        .subtract(30, "minutes")
                        .format("HH:mm");
                    this.minfin = moment(res.heureOuverture, "HH:mm")
                        .add(30, "minutes")
                        .format("HH:mm");
                    this.maxfin = moment(res.heureFermeture, "HH:mm").format(
                        "HH:mm"
                    );
                    //? This code is placed here because we need to wait for the return value of time
                    if(this.fullDay && !this.eventForm.get('tempsDebut').value){
                        this.eventForm.get('tempsDebut').setValue(this.mindebut)
                        this.eventForm.get('tempsFin').setValue(this.maxfin)
                    }
                },
                error:(err) => {
                    this.errService.popError(
                        err,
                        "Un probléme s'est produit lors de chargement. \n Essayer de nouveau."
                    );
                }
            });

            // * TPYE ACON new
            if (this._data.action == "new") {
                this.addEventInitialTreatment();
                this.setParamsAdd()
                this.bankCtrl.setValue(this.getFiltredCands()[0]);
                // load the initial bank list
                this.filteredBanks.next(this.getFiltredCands());
                // listen for search field value changes
                this.bankFilterCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterBanks();
                    });
                    //this.minDate = new Date(2022, 7, 2);
            } 
                
            // * TPYE EDIT
            else {
            this.editEventInitialTreatment();
            this.setParamsEdit()
            this.bankCtrl.setValue(this.getFiltredCands()[0]);
            // load the initial bank list
            this.filteredBanks.next(this.getFiltredCands());
            // listen for search field value changes
            this.bankFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterBanks();
                });
            }

            if(this._data.cands.length == 1) {
                this.filterCenterByCategory(this._data.cands[0].id);
            }
            this.eventForm.get('candidatId')?.valueChanges.subscribe(value => {
                this.filterCenterByCategory(value);
              });
            console.log("this._data" , this._data)

               //? only admin or propriteure that can edit or delete confermed event
            if (this.globalService.getUser().role != "proprietaire" && this.globalService.getUser().role != "administrateur" && this._data.completed ) {
                this.allow_delete = false;
            }
        } else {
            //! add error
        }
        console.log("Candidats " , this._data.cands)
    }


    

    /* 
        ! 3 possible values for this.minDate : null | biggestDate(false) | biggestDate(true)
    */
   setParamsAdd(){
    console.log('ADDING PARAMS',);
    //! MUST BE IN THIS ORDER
    if(!this._data.condEx) return

    this.setMinDateAdd();
    this.setDisableAdd();
    this.setStartingCondTypeAdd()
    this.setDateAdd()
   }

   setParamsEdit(){
    if(!this._data.condEx || this._data.action != 'edit') return
    if(new Date(this._data?.event?.debut) > new Date(this.jourJ)){
        console.log('MET 1',this._data?.event?.debut)
        this.minDate = new Date(this.jourJ)//moment(new Date(this.jourJ))
    }
    else {
        //this.maxDate = new Date(this.jourJMinusOne)
        console.log('MET 2',this._data?.event?.debut)
    
    }
   }

    setMinDateAdd(){
        if (this._data.cond_type == 'parc') this.minDate = this.biggestDate(true);
        if(!this._data.fromCand) return
        //! from candidat
        console.log('STILL IN',this.biggestDate(false),this.biggestDate(true));
        if(this._data.cond_type == 'circuit') this.minDate = this.biggestDate(false);
    }

    setDisableAdd(){
        if(this._data.fromCand)
            this.disable_cond_type = true;
        else
            this.disable_cond_type = false;
    }
    /* 
    ! 3 possible values 
    **cond_type = null (conduite) 
                | 'parc' 
                | 'circuit' ()
        ? ALWAYS STAYS THE SAME UNLESS IT'S CIRCUIT AND JOURJ didnt come yet

    */
    setStartingCondTypeAdd(){
        //!
        if(this._data.fromCand && this._data.cond_type == 'circuit' && this._data.date < new Date(this.jourJ)) {
            this.changedTypeFromCircToCond = true;
            this._data.cond_type = null;
        }
        /* if(this._data.cond_type == 'parc' && !this._data.cond_type) return
        else if(this._data.cond_type == 'circuit'){
            if(this._data.date < this.jourJ) this._data.cond_type = 'null'
            else return
        } */
    }

    setDateAdd(){
        if((this._data.cond_type == 'parc' && this._data.fromCand && this._data.date < this.biggestDate(true)) ||
           (this._data.cond_type && !this._data.fromCand && this._data.date < new Date(this.jourJ))){
                this._data.date = this.biggestDate(true);
                }
        else if((this._data.cond_type == 'circuit' || this.changedTypeFromCircToCond) && this._data.fromCand && this._data.date < this.biggestDate(false)){
            this._data.date = this.biggestDate(false);
        }
        /* else if(!this._data.cond_type && !this._data.fromCand && this._data.date >= new Date(this.jourJ))
                this._data.date = new Date(this.jourJMinusOne); */
    
        //! if the form is initialised we change the value inside the form
        if(this.eventForm?.get("debut")) this.eventForm.get("debut").setValue(this._data.date)
    }


    //* Return the biggestDate of previous exams and jourJ
    biggestDate(includeJourJ:boolean = true) {
        /* return arr.reduce(function (p, v) {
          return ( p > v ? p : v );
        }); */
        let arr = [];

        //! if there is a candidate
        if(this._data.cand || this.eventForm?.get('candidatId')?.value){
            const candId =  this._data.cand ?? this.eventForm?.get('candidatId')?.value
            const cand = this._data.cands.filter(e=>{return e.id == candId})[0]
            arr = [cand.max_code,cand.max_parc,cand.max_circuit,cand.max_conduite]
        }
        if(includeJourJ) arr.push(this.jourJ)
        arr = arr.filter(e=>{return !!e})
        if(arr.length < 1) return null
        return arr.map((elem)=>{return new Date(elem)}).reduce((p,v)=>{return(p>v ? p : v)})
      }


      
      //! Called when cond_type changed!
    setDirty(type?){
      //  console.log('DIRR')
       // this.eventForm.get("location").setValue(null);

       this.eventForm.controls["location"].markAsDirty();
       this.setMinDateAdd();
       this.setDateAdd();
       this.filterBanks();

       if(!this._data.fromCand && this.eventForm?.controls["candidatId"] && this.getFiltredCands() && this.getFiltredCands().map((e)=>{return e.id}).indexOf(this.eventForm.controls["candidatId"]?.value) == -1 ||
       this.biggestDate() > new Date(this.eventForm.controls["debut"]?.value))
       {
                this.eventForm.controls["candidatId"].setValue(null);
                this.minDate = null;
            }

        /*this.eventForm.controls["completed"].markAsTouched();
       this.eventForm.markAllAsTouched() */
    }

    toggleCompleted() {
        let comp = this.eventForm.get("completed").value;
        this.eventForm.get("completed").setValue(!comp);
        if (comp) this.eventForm.get("absent").setValue(true);
        else this.eventForm.get("absent").setValue(false);
        this.eventForm.controls["completed"].markAsDirty();
    }

    togglePresent() {
        let abs = this.eventForm.get("absent").value;
        this.eventForm.get("absent").setValue(!abs);
        this.eventForm.controls["absent"].markAsDirty();
    }
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent> = null;

    /** list of banks */
    public banks: any[] = [];

    /** control for the selected bank */
    public bankCtrl: UntypedFormControl = new UntypedFormControl();

    /** control for the MatSelect filter keyword */
    public bankFilterCtrl: UntypedFormControl = new UntypedFormControl("");

    /** list of banks filtered by search keyword */
    public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();
    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }
    daysSelected: any[] = [];
    daysShow: any[] = [];
    input = null;
    events: any;
    isSelected = (events: any) => {
        const date = moment(events).format("L");

        return this.daysSelected.find((x) => x == date) ? "selected" : null;
    };

    /* select(events: any, calendar: any) {
        const date = moment(events).format("L");
        const index = this.daysSelected.findIndex((x) => x == date);
        if (index < 0) {
            this.daysSelected.push(date);
            this.daysShow.push(moment(events).format());
        } else {
            this.daysSelected.splice(index, 1);
            this.daysShow.splice(index, 1);
        }
        this.input = "";
        this.daysSelected.forEach((element) => {
            if (this.input == "") {
                this.input = this.input + element;
            } else {
                this.input = this.input + "," + element;
            }
        });
        this.eventForm.controls["debut"].setValue(this.input);

        calendar.updateTodaysDate();
    } */

    




    openRenFra(candidatId) {
        let candidat = this._data.cands.find((elem) => {
            return elem.id == candidatId;
        });
        if (candidat.tentCo > 1 && this._data.codeEx) {
            this.openRenouv = true;
        } else {
            this.openRenouv = false;
        }
        if (this._data.condEx) {
            this.openRenouv = true;
            this.openFrai = true;
        }
    }

    checkCompleted() {
        if (this._data.completed) {
            this.eventForm.disable();
            return 0;
        }
    }
    setAccessOnEdit() {
        if (this._data.canEdit) {
            if (this.globalService.getUser().role == "moniteur") {
                this.eventForm.controls["agentResp"].disable();
            }
        }
    }

    setAccessOnNew() {
        if (this.globalService.getUser().role == "moniteur") {
            this.eventForm.controls["agentResp"].disable();
        }
    }
    addEventInitialTreatment() {
        switch (this._data.categorie) {
            case "codeEx":
                this.createFormCodeExam();
                break;
            case "codeLess":
                this.createFormCodeLess();

                break;
            case "conduiteEx":
                this.createFormConduiteExam();
                this.setAccessOnNew();

                break;
            case "conduiteLess":
                this.createFormConduiteLess();
                this.setAccessOnNew();

                break;
            case "busy":
                this.createFormBusy();
                this.setAccessOnNew();

                break;
        }
    }

    //? On click fullday checkbox
    fullDayAction(fullDay){
        if(fullDay) {
            this.eventForm.controls['fin'].addValidators(Validators.required)
            this.eventForm.controls['tempsDebut'].removeValidators(Validators.required)
            this.eventForm.controls['tempsFin'].removeValidators(Validators.required)
        }
        else{
            this.eventForm.controls['fin'].removeValidators(Validators.required)
            this.eventForm.controls['tempsDebut'].addValidators(Validators.required)
            this.eventForm.controls['tempsFin'].addValidators(Validators.required)
        }
    }

    checkDeletable(){
        if(this._data.categorie == 'busy') return
        let candidat = this._data.cands[0];
        if(candidat.max_circuit) candidat.max_conduite = (new Date(candidat.max_circuit) > new Date(candidat.max_conduite)) 
                                                            ? candidat.max_circuit : candidat.max_conduite
        console.log('ca',candidat)
        let event = this._data.event;
        if(event.final){
            //* examen code
            if(event.type == 'codeEx'){
                    if ((candidat.phase == 'code')
                    || (candidat.phase == 'conduite' && candidat.cond_type !='parc' && !candidat.dateExCon && candidat.tentCon < 2)){
                        this.deletable = true
                    }
                    else{
                        this.deletable = false
                    }
            }

            //* examen conduite circuit
            else if(event.type == 'conduiteEx' && event.cond_type != 'parc'){
                console.log('HNAAAAAAA',candidat.tentParc % 2,
                (candidat.phase == 'conduite' && candidat.cond_type == 'parc' && !candidat.dateExParc && (candidat.tentParc % 2 != 0)))
                
                if((candidat.phase == 'conduite' && candidat.cond_type != 'parc' && ((candidat.tentCon == event.tentative && event.success) || !event.success) )
                || (candidat.phase == 'conduite' && candidat.cond_type == 'parc' && (!candidat.dateExParc && candidat.tentParc % 2 != 0))){
                    this.deletable = true
                }
                else{
                    this.deletable = false
                }

            }

            //* examen conduite park
            else if(event.type == 'conduiteEx' && event.cond_type == 'parc'){
                if(event.success || !event.completed
                    ||(!event.success && !candidat.dateExCon && candidat.cond_type != 'parc' && candidat.tentParc == event.tentative && (new Date(event.debut).getTime() > new Date(candidat.max_conduite).getTime()))
                    ||( !candidat.dateExParc && candidat.cond_type == 'parc' && (new Date(event.debut).getTime() > new Date(candidat.max_conduite).getTime()))
                    ){
                        this.deletable = true
                }
                else{
                    this.deletable = false
                }
            }
        }
        else{
            this.deletable = false
        }
    }

    editEventInitialTreatment() {

        this.tel1 = this._data.cands[0]?.tel1;
        this.checkDeletable();

        this.currAgent = this.globalService.getUser();
        if (this._data.event.frai) this.fraiAuDepart = this._data.event.frai;
        if (this._data.event.renouvellement)
            this.renouvAuDepart = this._data.event.renouvellement;
        
        switch (this._data.categorie) {
            case "codeEx":
                this.editFormCodeExam();
                //! Responsible on disabling the form
                //this.checkCompleted();
                break;
            case "codeLess":
                this.editFormCodeLess();
                this.checkCompleted();
                break;
            case "conduiteEx":
                this.editFormConduiteExam();
                this.setAccessOnEdit();
                
                //! no need to check if its completed for now
              //  this.checkCompleted();
                break;
            case "conduiteLess":
                this.editFormConduiteLess();
                this.setAccessOnEdit();
                //! no need to check if its completed for now
                this.checkCompleted();
                break;
            case "busy":

                this.initFullDay();
                this.editFormBusy();
                this.setAccessOnEdit();
                break;
        }
        console.log("this._data.cands[0]?.id : " , this._data.cands[0]?.id)
        this.filterCenterByCategory(this._data.cands[0]?.id);


    }

    filterCenterByCategory(candidatId) {
        
        if (this._data.centers) {
            const candidat = this._data.cands.filter(e=>{return e.id == candidatId})[0]
            const categorieToFilter = candidat.type.replace("Type ", "");

            this.filtred_center = this._data.centers.filter(item => item.categorie === categorieToFilter);
            
            this.candidatSelected = true;
        }   
    }

    initFullDay(){
        let diff = new Date(this._data.event.fin).getTime() - new Date(this._data.event.debut).getTime() 
        console.log('diff',diff);
        diff = diff /(60*60*1000*24)
        console.log('diff',diff,(diff>=0.99));
        if(diff>=0.99) {
            this.fullDay = true
        };
    }


    ngAfterViewInit() { }

    ngOnDestroy() {
        if (window.history.state.modal) {
            history.go(-1);
        }

        this._onDestroy.next();
        this._onDestroy.complete();
    }
    /**
     * Sets the initial value after the filteredBanks are loaded initially
     */
    protected setInitialValue() {
        this.filteredBanks
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                // setting the compareWith property to a comparison function
                // triggers initializing the selection according to the initial value of
                // the form control (i.e. _initializeSelection())
                // this needs to be done after the filteredBanks are loaded initially
                // and after the mat-option elements are available
                this.singleSelect.compareWith = (a: any, b: any) =>
                    a && b && a.id === b.id;
            });
    }
    protected filterBanks() {
        if (!this.getFiltredCands()) {
            return;
        }
        // get the search keyword
        let search = this.bankFilterCtrl.value;
        if (!search) {
            this.filteredBanks.next(this.getFiltredCands());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        /* this.cands = this.cands.filter(
            bank => bank.username.toLowerCase().indexOf(search) > -1
        ); */
        this.filteredBanks.next(
            this.getFiltredCands().filter(
                (bank) => bank.username.toLowerCase().indexOf(search) > -1
            )
        );
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    // datefin() {
    //     let d1 = this.eventForm.get("tempsDebut").value;
    //     let d2 = this.eventForm.get("tempsFin").value;
    //     d2 = d2.substring(0, 2);
    //     d1 = d1.substring(3, 5);
    //     console.log(d2, d1);
    //     this.eventForm.get("tempsFin").setValue(d2 + ":" + d1);
    //     this.minfin = moment(this.eventForm.get("tempsDebut").value, "HH:mm")
    //         .add(1, "hours")
    //         .format("HH:mm");
    //     {
    //         this.eventForm
    //             .get("tempsFin")
    //             .setValue(
    //                 moment(this.eventForm.get("tempsDebut").value, "HH:mm")
    //                     .add(1, "hours")
    //                     .format("HH:mm")
    //             );
    //     }
    // }

    deleteResult() {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: false,
            }
        );

        this.confirmDialogRef.componentInstance.confirmMessage =
            "Supprimer la résultat ?";

        this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
            if (result) {
                //const eventIndex = this.events.indexOf(ev);
                //this.events.splice(eventIndex, 1);
                //this.events.push(ev);
                this._calendarService
                    .deleteResultExam(this._data.event)
                    .subscribe((qres) => {
                        this.eventForm.get("completed").setValue(false);

                        this._data.event.result = null;
                        this._data.completed = false;
                        this._data.event.echecCircuit = null;
                        this._data.event.echecCode = null;
                        this._data.event.resultat = null;
                        this.updated = true;
                        this._data.canEdit = true;
                        this.enableForm();
                        if(this.globalService.getUser().role != 'proprietaire' && this.globalService.getUser().role != 'administrateur'){
                            this.eventForm.get('renouvellement')?.disable()
                            this.eventForm.get('frai')?.disable()
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    addResult() {
        if (this._data.condEx) this.resultExCond();
        else if (this._data.codeEx) this.resultExCode();
    }


    goToCandidate(){
        if(this.eventForm.get('candidatId').value){
            this.router.navigate(["/candidats/"+this.eventForm.get('candidatId').value]);
            this._eventFlowService.setEventDialogIsOpenToFalse()
        }
    }

    resultExCond() {
        this.dialogRef = this._matDialog.open(ExamConduiteResultComponent, {
            panelClass: ["common-dialog-style","exm-cond-result-d-style"],
            data: {
                type: "conduiteEx",
                cond_type: this._data.cond_type ? this._data.cond_type : '' ,
                date: this.eventForm.get("debut").value,

                candidatId: this._data.event.candidatId,
            },
        });
        this.dialogRef.afterClosed().pipe(take(1)).subscribe((res) => {
            if (res) {
                console.log('res :',res)
                this.updated = true;
                if (res.success) {
                    this._data.completed = true;
                    this._data.event.completed = true;
                    this.eventForm.get("completed").setValue(true);
                    this._data.event.success = true;
                    this._data.event.echecCircuit = false;
                    this._data.event.echecParc = false;
                } else {
                    this._data.completed;
                    this.eventForm.get("completed").setValue(true);
                    this._data.event.completed = true;
                    this._data.event.success = false;
                    if(!res.echecA){
                        this._data.event.echecCircuit = false;
                        this._data.event.echecParc = false;
                    }
                    else if (res.echecA == "circulation") {
                        this._data.event.echecCircuit = true;
                        this._data.event.echecParc = false;
                    } else {
                        this._data.event.echecCircuit = false;
                        this._data.event.echecParc = true;
                    }
                }
                this.disableForm();
            }
        });
    }

    resultExCode() {
        this.dialogRef = this._matDialog.open(ExamResultComponent, {
                    panelClass: ["common-dialog-style","exam-code-result-d-style"],
            data: {
                action: "new",
                date: new Date(),
                type: "codeEx",
                agents: [],
                typeDePermis: this._data.cands[0].type,
                candidatId: this._data.cand,
            },
        });
        this.dialogRef.afterClosed().pipe(take(1)).subscribe((res) => {
            if (res) {
                this.updated = true;
                this.eventForm.get("completed").setValue(true);
                this._data.completed = true;
                this._data.event.completed = true;
                this._data.event.success = res.success;
                this._data.event.resultat = res.note;
                this.disableForm();
            }
        });
    }

    createFormCodeLess() {
        this.daysSelected.push(moment(this._data.date).format("L"));
        this.daysShow.push(this._data.date);
        this.eventForm = this.fb.group({
            candidatId: [this._data.cand, Validators.required],
            debut: [moment(this._data.date), Validators.required],
            tempsDebut: [this._data.start, Validators.required],
            duration: ["1", Validators.required],
            titre: [this._data.titre, Validators.required],
            completed: [false],
            absent: [false],
            comment: [null],
            location: [null],
            one_session_duration_in_minutes:this._data.blockedDuration
        });
    }

    createFormCodeExam() {
        let date = this._data.date;
        let renouv = 0;
        if (this._data.fromCand) {
            date = new Date();
            if (this._data.cands[0].tentative > 1) {
                this.openRenouv = true;
                this.openFrai = false;
                renouv = this._data.renouvelement;
            }
        }
        this.hasFrai = this._data.hasFrai;
        this.eventForm = this.fb.group({
            candidatId: [this._data.cand, Validators.required],
            debut: [date, Validators.required],
            tempsDebut: [this._data.start, Validators.required],
            duration: ["1", Validators.required,],
            titre: [this._data.titre, Validators.required],
            comment: [null],
            location: [null],
            completed: [false],
            centreExamen: [this._data.center],
            absent: [false],
            renouvellement: [renouv,Validators.required],
            one_session_duration_in_minutes:[this._data.blockedDuration,[Validators.required,Validators.min(30),Validators.max(60)]]
        });
    }
    createFormConduiteLess() {
        this.daysSelected.push(moment(this._data.date).format("L"));
        this.daysShow.push(this._data.date);
        this.eventForm = this.fb.group({
            candidatId: [this._data.cand, Validators.required],
            agentResp: [this._data.agent, Validators.required],
            debut: [moment(this._data.date), Validators.required],
            tempsDebut: [this._data.start, Validators.required],
            duration: ["1", Validators.required],
            titre: [this._data.titre, Validators.required],
            vehiculeId: [this._data.vehicule, Validators.required],
            completed: [false],
            absent: [false],
            comment: [null],
            location: [null],
            one_session_duration_in_minutes:this._data.blockedDuration
        });
    }

    createFormConduiteExam() {
        let date = this._data.date;
        this.openRenouv = true;
        this.openFrai = true;
        if (this._data.fromCand) {
            date = new Date();
            if (this._data.cands[0].tentative > 1) {
                this.openRenouv = true;
            }
        }
        this.eventForm = this.fb.group({
            candidatId: [this._data.cand, Validators.required],
            agentResp: [this._data.agent, Validators.required],
            debut: [moment(this._data.date), Validators.required],
            tempsDebut: [this._data.start, Validators.required],
            duration: ["1", Validators.required],
            titre: [this._data.titre, Validators.required],
            vehiculeId: [this._data.vehicule, Validators.required],
            cond_type: [null],
            comment: [null],
            location: [null],
            centreExamen: [this._data.center],
            completed: [false],
            absent: [false],
            frai: [this._data.frai],
            renouvellement: [this._data.renouvelement,Validators.required],
            one_session_duration_in_minutes:[this._data.blockedDuration,[Validators.required,Validators.min(30),Validators.max(60)]]
        });
    }

    createFormBusy(){
        let date = this._data.date;
        console.log(date)
        this.openRenouv = false;
        this.openFrai = false;

        this.eventForm = this.fb.group({
            agentResp: [this._data.agent, Validators.required],
            debut: [moment(this._data.date), Validators.required],
            fin: [moment(this._data.date)],
            tempsDebut: [this._data.start, Validators.required],
            tempsFin: [moment(this._data.start,'HH:mm').add(1,'hours').format('HH:mm'), Validators.required],
            titre: [this._data.titre, Validators.required],
            comment: [null],
        });
    }

    editFormCodeLess() {
        this.eventForm = this.fb.group({
            candidatId: [
                { value: this._data.cand, disabled: true },
                Validators.required,
            ],
            debut: [this._data.event.debut, Validators.required],
            tempsDebut: [
                new Date(this._data.event.debut).getHours() +
                ":" +
                new Date(this._data.event.debut).getMinutes(),
                Validators.required,
            ],
            duration: [
                this._data.event.duration &&
                    (this._data.event.duration === 1 || this._data.event.duration === 0.5 || this._data.event.duration === 1.5 ||
                        this._data.event.duration === 2)
                    ? this._data.event.duration.toString(10)
                    : "1",
                Validators.required,
            ],
            titre: [this._data.event.titre, Validators.required],
            comment: [this._data.event.comment],
            location: [this._data.event.location],
            completed: [this._data.event.completed],
            absent: [this._data.event.absent],
            one_session_duration_in_minutes:this._data.event.one_session_duration_in_minutes
        });
    }

    /* togglePresence() {
       // console.log("ONCE");
        this.isLoading = true;
        this._calendarService
            .togglePresence(this._data.event.id, !this._data.event.absent)
            .subscribe((res) => {
                this.isLoading = false;
                if (res) {
                    this._data.event.absent = !this._data.event.absent;
                }
            });
    } */

    editFormCodeExam() {
        let date = this._data.date;

        if (this._data.fromCand) {
            date = new Date();
        }
        if (this._data.event.tentative > 1) this.openRenouv = true;
        this.hasFrai = this._data.hasFrai;
        this.eventForm = this.fb.group({
            candidatId: [
                { value: this._data.cand, disabled: true },
                Validators.required,
            ],
            debut: [this._data.event.debut, Validators.required],
            tempsDebut: [
                new Date(this._data.event.debut).getHours() +
                ":" +
                new Date(this._data.event.debut).getMinutes(),
                Validators.required,
            ],
            duration: ["1", Validators.required],
            titre: [this._data.event.titre, Validators.required],
            comment: [this._data.event.comment],
            location: [this._data.event.location],
            centreExamen: [this._data.event.centreExamen],
            completed: [this._data.event.completed],
            absent: [this._data.event.absent],
            renouvellement: [this._data.event.renouvellement,Validators.required],
            one_session_duration_in_minutes:[this._data.event.one_session_duration_in_minutes,[Validators.required,Validators.min(30),Validators.max(60)]]
        });
        if(this.globalService.getUser().role != 'proprietaire' && this.globalService.getUser().role != 'administrateur'){
            this.eventForm.get('renouvellement')?.disable()
        }
    }

    editFormConduiteLess() {
        //? If no vehicle assigned assign clinet_vehicle
        if(this._data.cond_type == 'recyclage' && !this._data.event.vehiculeId){
            this._data.vehicule = 'client_vehicle'
        }
        this.eventForm = this.fb.group({
            candidatId: [
                { value: this._data.cand, disabled: true },
                Validators.required,
            ],
            agentResp: [this._data.agent, Validators.required],
            debut: [this._data.event.debut, Validators.required],
            tempsDebut: [
                new Date(this._data.event.debut).getHours() +
                ":" +
                new Date(this._data.event.debut).getMinutes(),
                Validators.required,
            ],
            duration: [
                this._data.event.duration &&
                (this._data.event.duration === 1 || this._data.event.duration === 0.5 || this._data.event.duration === 1.5 ||
                    this._data.event.duration === 2)
                    ? this._data.event.duration.toString(10)
                    : "1",
                Validators.required,
            ],
            titre: [this._data.event.titre, Validators.required],
            comment: [this._data.event.comment],
            location: [this._data.event.location],
            vehiculeId: [this._data.vehicule, Validators.required],
            completed: [this._data.event.completed],
            absent: [this._data.event.absent],
            frai: [this._data.event.frai],
            one_session_duration_in_minutes:this._data.event.one_session_duration_in_minutes
        });
    }

    setSessionDuration(duration){
        this.eventForm.controls['one_session_duration_in_minutes'].setValue(duration)
        this.eventForm.controls['one_session_duration_in_minutes'].markAsDirty()
    }

    editFormBusy(){
        let tempsDebut = this.mindebut;
        let tempsFin = this.maxfin;
        if(!this.fullDay){
            tempsDebut = new Date(this._data.event.debut).getHours() +
                                ":" +
                                new Date(this._data.event.debut).getMinutes();
            tempsFin = new Date(this._data.event.fin).getHours() +
                                ":" +
                                new Date(this._data.event.fin).getMinutes()
        }

        this.eventForm = this.fb.group({
            agentResp: [this._data.agent, Validators.required],
            debut: [this._data.event.debut, Validators.required],
            fin: [this._data.event.fin],
            tempsDebut: [
                tempsDebut,
                Validators.required,
            ],
            tempsFin: [
                tempsFin,
                Validators.required,
            ],
            titre: [this._data.event.titre, Validators.required],
            comment: [this._data.event.comment],
        });
    }

    editFormConduiteExam() {
        this.openFrai = true;
        this.openRenouv = true;
        let date = this._data.date;
        if (this._data.fromCand) {
            date = new Date();
        }
        this.eventForm = this.fb.group({
            //id: [this._data.event.id],this._data.event.id
            candidatId: [
                { value: this._data.cand, disabled: true },
                Validators.required,
            ],
            agentResp: [this._data.agent, Validators.required],
            debut: [this._data.event.debut, Validators.required],
            tempsDebut: [
                new Date(this._data.event.debut).getHours() +
                ":" +
                new Date(this._data.event.debut).getMinutes(),
                Validators.required,
            ],
            duration: ["1", Validators.required],
            titre: [this._data.event.titre, Validators.required],
            comment: [this._data.event.comment],
            location: [this._data.event.location],
            vehiculeId: [this._data.vehicule, Validators.required],
            renouvellement: [this._data.event.renouvellement,Validators.required],
            centreExamen: [this._data.event.centreExamen],
            completed: [this._data.event.completed],
            absent: [this._data.event.absent],
            frai: [this._data.event.frai],
            one_session_duration_in_minutes:[this._data.event.one_session_duration_in_minutes,[Validators.required,Validators.min(30),Validators.max(60)]]
        });
        if(this.globalService.getUser().role != 'proprietaire' && this.globalService.getUser().role != 'administrateur'){
            this.eventForm.get('renouvellement')?.disable()
            this.eventForm.get('frai')?.disable()
        }
    }
    

    disableForm() {
        this.eventForm.disable();

    }

    enableForm() {
        this.canEdit = false;
        this.eventForm.enable();

    }

    //! Treatment that happens on every form
    communForm(event) {
        for (let i = 0; i < this._data.cands?.length; i++) {
            if (
                this._data.cands[i].id ===
                this.eventForm.controls["candidatId"]?.value
            ) {
                event.numCont = this._data.cands[i].numContrat;
                if (this._data.codeEx)
                    event.tentative = this._data.cands[i].tentCo;
                else if (this._data.condEx)
                    event.tentative = this._data.cands[i].tentCon;
            }
        }

        
        if(this._data.categorie == 'busy'){
            if(this.fullDay){
                event.debut = moment(
                    moment(event.debut).format("DD/MM/YYYY") + " " + "00:00",
                    "DD/MM/YYYY HH:mm"
                ).format();
                event.fin = moment(
                    moment(event.fin).format("DD/MM/YYYY") + " " + "23:59",
                    "DD/MM/YYYY HH:mm"
                ).format();
            }
            else{
                event.debut = moment(
                    moment(event.debut).format("DD/MM/YYYY") + " " + event.tempsDebut,
                    "DD/MM/YYYY HH:mm"
                ).format();
                event.fin = moment(
                    moment(event.debut).format("DD/MM/YYYY") + " " + event.tempsFin,
                    "DD/MM/YYYY HH:mm"
                ).format();
            }
        }
        else{
            let d = moment(
                moment(event.debut).format("DD/MM/YYYY") + " " + event.tempsDebut,
                "DD/MM/YYYY HH:mm"
            );

            event.debut = d.format();

            event.fin = d.add(this.eventForm.controls["one_session_duration_in_minutes"]?.value
            *this.eventForm.controls["duration"]?.value,'minutes').format();
        }

        // event.debut = event.debut.hour(
        //     parseInt(event.tempsDebut.substring(0, 2))
        // );
        // event.debut = event.debut.minute(
        //     parseInt(event.tempsDebut.substring(3, 5))
        // );
        // event.debut = event.debut.format();
    }

    addEventCodeEx(event) {
        event.final = true;
        // event.fin = moment(event.debut).add(1, "hour").format();
        if (event.tentative === 1) {
            event.frai = 0;
        }
    }

    addEventConduiteEx(event) {
        event.final = true;
        event.cond_type = this._data.cond_type ? this._data.cond_type : null;
        // event.fin = moment(event.debut).add(1, "hour").format();
    }

    //? Calculating the duration of a busy event
    addEventBusy(event){
        let duration = moment.duration(moment(event.fin).diff(moment(event.debut)));
        let hours = duration.asHours();
        if((hours - Math.round(hours)) < 0.25) hours = Math.round(hours)
        event.duration = hours;
    }

    addEventCodeLess(event) {
        // event.fin = event.fin.hour(parseInt(event.tempsFin.substring(0, 2)));
        // event.fin = event.fin.minute(parseInt(event.tempsFin.substring(3, 5)));
        // event.fin = event.fin.format();
    }
    addEventConduiteLess(event) {

        // event.fin = event.fin.hour(parseInt(event.tempsFin.substring(0, 2)));
        // event.fin = event.fin.minute(parseInt(event.tempsFin.substring(3, 5)));
        // event.fin = event.fin.format();
    }

    closeThis() {

        if (this.updated) {
            this._matDialogRef.close(["save"]);
            this.eventAction.setCalendarAction("save");
        } 

        else this._matDialogRef.close();
    }

    logui() {
        setTimeout(() => { }, 1000);
    }

    addEventt() {
        if (!this.addingEvent) {
            this.addingEvent = true;
            let event = this.eventForm.getRawValue();
            event.type = this._data.categorie;

            switch (this._data.categorie) {
                case "codeEx":
                    this.communForm(event);
                    this.addEventCodeEx(event);
                    delete event.tempsDebut;
                    if (!this.openRenouv) {
                        event.renouvellement = 0;
                    }
                    this._calendarService.addEvent(event).subscribe((qres) => {
                        this._reloadService.pushChanges("calendar");
                        this.addingEvent = false;
                        if (qres != null) {
                            if (qres.message && qres.message == "conflit") {
                                this.errors = true;
                                this.conflit =
                                    qres.confType +
                                    " : pas disponible(s) à cette heure";
                            } else {
                                this._matDialogRef.close(["save"]);
                                this.eventAction.setCalendarAction(qres);
                                this.snackBar.openFromComponent(
                                    SuccesSnakBarComponent,
                                    {
                                        duration: 3000,
                                    }
                                );
                            }
                        }
                    });
                    break;
                case "codeLess":
                    let events = [];
                    if (this.SessionArray.length === 0) {
                        this.communForm(event);
                        this.addEventCodeLess(event);
                        delete event.tempsDebut;
                        events.push(event);
                    } else {
                        this.addLastEvent();
                        this.SessionArray.forEach((element) => {
                            let event = this.eventForm.getRawValue();
                            event.type = this._data.categorie;
                            this.communForm(event);
                            this.addEventCodeLess(event);
                            event.debut = element.debut.format();
                            event.fin = element.fin.format();
                            event.completed = element.confirmed;
                            event.absent = element.absent;
                            delete event.tempsDebut;
                            events.push(event);
                        });
                    }
                    this._calendarService
                        .addMultipleEvents(events)
                        .subscribe((qres) => {

                            let errors = []
                            this._reloadService.pushChanges("calendar");
                            this.addingEvent = false;
                            for (let i = 0; i < qres.length; i++) {
                                if (qres[i] == null) {
                                    qres.splice(i, 1);
                                    i--;
                                }
                            }

                            if (qres.length > 0) {
                                //! PREVIOUSLY WE WANTED TO DELETE EVENTS WITH CONFLICT FROM THE LIST! BUT THERE IS NO NEED! WE CAN DELETE ALL THE LIST AND SHOW THE ONES THAT WERENT ADDED
                                /* for (let i = 0; i < qres.length; i++) {
                                    for (let j = 0; j < events.length; j++) {
                                        if (moment(moment(qres[i][0].debut).format()).isSame(moment(events[0].debut).format())) {
                                            events.splice(j, 1);
                                            this.SessionArray.splice(j, 1);
                                            j--;
                                        }
                                    }
                                } */
                                this.SessionArray = [];
                                /* this.eventForm
                                    .get("debut")
                                    .setValue(moment(this._data.date)); */
                                for (const element of qres) {
                                    element[0].debut = moment(element[0].debut).format("Do MMMM, [à] h:mm")

                                    //element[0].debut = moment(element[0].debut).format("L")
                                    errors.push(element[0])
                                }
                                this.errorsArray = errors;
                                this.multiErrors = true;
                            } else {
                                this._matDialogRef.close(["save"]);
                                this.eventAction.setCalendarAction(qres);
                                this.snackBar.openFromComponent(
                                    SuccesSnakBarComponent,
                                    {
                                        duration: 3000,
                                    }
                                );
                            }
                        });
                    break;
                case "conduiteEx":
                    this.communForm(event);
                    this.addEventConduiteEx(event);
                    delete event.Duree;
                    delete event.tempsDebut;
                    this._calendarService.addEvent(event).subscribe((qres) => {
                        this._reloadService.pushChanges("calendar");
                        this.addingEvent = false;
                        if (qres != null) {
                            if (qres.message && qres.message == "conflit") {
                                this.errors = true;
                                this.conflit =
                                    qres.confType +
                                    " : pas disponible(s) à cette heure";
                            } else {
                                this._matDialogRef.close(["save"]);
                                this.eventAction.setCalendarAction(qres);
                                this.snackBar.openFromComponent(
                                    SuccesSnakBarComponent,
                                    {
                                        duration: 3000,
                                    }
                                );
                            }
                        }
                    });
                    break;
                case "conduiteLess":
                    let eventsconduite = [];
                    console.log('rrrrr',(event.vehiculeId == undefined || event.vehiculeId == 'client_vehicle') ? null : event.vehiculeId);
                    if (this.SessionArray.length === 0) {
                        this.communForm(event);
                        this.addEventConduiteLess(event);
                        delete event.tempsDebut;
                        event.cond_type = this._data.cond_type ? this._data.cond_type : null;

                        event.vehiculeId = (event.vehiculeId == undefined || event.vehiculeId == 'client_vehicle') ? null : event.vehiculeId
                        eventsconduite.push(event);
                    } else {
                        this.addLastEvent();
                        this.SessionArray.forEach((element) => {
                            let event = this.eventForm.getRawValue();
                            event.type = this._data.categorie;
                            event.cond_type = this._data.cond_type ? this._data.cond_type : null;
                            this.communForm(event)
                            this.addEventConduiteLess(event);
                            event.debut = element.debut.format();
                            event.fin = element.fin.format();
                            event.completed = element.confirmed;
                            event.absent = element.absent;
                            event.agentResp = element.agentResp;
                            event.vehiculeId = (element.vehiculeId == undefined || element.vehiculeId == 'client_vehicle') ? null : event.vehiculeId
                            event.titre = element.titre
                            delete event.tempsDebut;
                            eventsconduite.push(event);
                        });
                        console.log('events conduite',eventsconduite);
                    }
                    this._calendarService
                        .addMultipleEvents(eventsconduite)
                        .subscribe((qres) => {
     

                            let errors = []
                            this._reloadService.pushChanges("calendar");
                            this.addingEvent = false;
                            for (let i = 0; i < qres.length; i++) {
                                if (qres[i] == null) {
                                    qres.splice(i, 1);
                                    i--;
                                }
                            }

                            if (qres.length > 0) {
                                //! OLD CODE TO DELETE THE ONES WITH CONFLICT
                                /* for (let i = 0; i < qres.length; i++) {
                                    for (let j = 0; j < eventsconduite.length; j++) {
                                        if (moment(moment(qres[i][0].debut).format()).isSame(moment(eventsconduite[0].debut).format())) {
                                            eventsconduite.splice(j, 1);
                                            this.SessionArray.splice(j, 1);
                                            j--;
                                        }
                                    }
                                } */

                                this.SessionArray = [];
                                
                                //! OLD CODE THAT CAUSES ERRO IN CONFLICT
                                // qres.forEach(element => {
                                //     element.debut = moment(element.debut).format("L")
                                // });
                                // this.errorsArray = qres;
                                // this.multiErrors = true;

                                //! NEW CODE 
                                //? REMOVED DATE RESET, REQUESTED BY LOUAY
                                /* this.eventForm
                                    .get("debut")
                                    .setValue(moment(this._data.date)); */
                                for (const [i,element] of qres?.entries()) {
                                    if(i == 0){
                                        this.eventForm
                                            .get("debut")
                                            .setValue(moment(element[0].debut));
                                    }
                                    element[0].debut = moment(element[0].debut).format("MMMM Do, [à] h:mm")
                                    errors.push(element[0])
                                }
                                console.log('errors',errors);
                                this.errorsArray = errors;
                                this.multiErrors = true;
                            } else {
                                this.eventForm
                                    .get("debut")
                                    .setValue(moment(this._data.date));
                                this._matDialogRef.close(["save"]);
                                this.eventAction.setCalendarAction(qres);
                                this.snackBar.openFromComponent(
                                    SuccesSnakBarComponent,
                                    {
                                        duration: 3000,
                                    }
                                );
                            }
                        });
                    break;
                case "busy":
                    this.communForm(event);
                    this.addEventBusy(event);
                    delete event.tempsDebut;
                    delete event.tempsFin;
                    this._calendarService.addEvent(event).subscribe((qres) => {
                        this._reloadService.pushChanges("calendar");
                        this.addingEvent = false;
                        if (qres != null) {
                            if (qres.message && qres.message == "conflit") {
                                this.errors = true;
                                this.conflit =
                                    qres.confType +
                                    " : pas disponible(s) à cette heure";
                            } else {
                                this._matDialogRef.close(["save"]);
                                this.eventAction.setCalendarAction(qres);
                                this.snackBar.openFromComponent(
                                    SuccesSnakBarComponent,
                                    {
                                        duration: 3000,
                                    }
                                );
                            }
                        }
                    });
                    break;
            }
        }
    }

    
    saveEventt() {
        if (!this.savingEvent){
            this.savingEvent = true;
            const form = this.eventForm.getRawValue();
            console.log('from',{...form});
            let data;
        if(this._data.categorie != "busy") {
            let d = moment(
                moment(form.debut).format("DD/MM/YYYY") + " " + form.tempsDebut,
                "DD/MM/YYYY HH:mm"
            );
            data = {
                numCont: this._data.event.numCont,
                frai: form.frai,
                id: this._data.event.id,
                candidatId: form.candidatId,
                debut: d.format(),
                fin: d.clone()
                        .add(
                            this.eventForm.controls["one_session_duration_in_minutes"]?.value
                            *this.eventForm.controls["duration"]?.value,'minutes'
                            ).format(),
                type: this._data.categorie,
                titre: form.titre,
                completed: form.completed,
                absent: form.absent,
                location: form.location,
                centreExamen: form.centreExamen,
                comment: form.comment,
                vehiculeId:
                    (form.vehiculeId == undefined || form.vehiculeId == 'client_vehicle') ? null : form.vehiculeId,
                agentResp: form.agentResp !== undefined ? form.agentResp : null,
                renouvellement:
                    form.renouvellement !== undefined
                        ? form.renouvellement
                        : 0,
                cond_type : this._data.cond_type ? this._data.cond_type : null,
                duration : form.duration,
                one_session_duration_in_minutes: form.one_session_duration_in_minutes
            };

            if (data.type === "codeEx" || data.type === "conduiteEx") {
                if (this.fraiModifier)
                    data["fraiDifference"] = this.differenceFrai;
                else data["fraiDifference"] = 0;

                if (this.renouvModifier)
                    data["renouvDifference"] = this.differenceRenouv;
                else data["renouvDifference"] = 0;
            } 
        }
        else {
            let debut; 
            let fin; 
            if(this.fullDay){
                debut = moment(
                    moment(form.debut).format("DD/MM/YYYY") + " " + "00:00",
                    "DD/MM/YYYY HH:mm"
                ).format();
                fin = moment(
                    moment(form.fin).format("DD/MM/YYYY") + " " + "23:59",
                    "DD/MM/YYYY HH:mm"
                ).format();
            }
            else{
                debut = moment(
                    moment(form.debut).format("DD/MM/YYYY") + " " + form.tempsDebut,
                    "DD/MM/YYYY HH:mm"
                ).format();
                fin = moment(
                    moment(form.debut).format("DD/MM/YYYY") + " " + form.tempsFin,
                    "DD/MM/YYYY HH:mm"
                ).format();
            }
        let duration = moment.duration(moment(fin).diff(moment(debut)));
        let hours = duration.asHours();
        if((hours - Math.round(hours)) < 0.25) hours = Math.round(hours)
            data = {
                id: this._data.event.id,
                debut: debut,
                fin: fin,
                duration:1,
                type: "busy",
                titre: form.titre,
                agentResp: form.agentResp,
                one_session_duration_in_minutes:null,
            };
            this.savingEvent = false;
        }
        this._calendarService.editEvent(data).subscribe({
            next:(qres) => {
                this.savingEvent = false;
                if (qres.message && qres.message == "conflit") {
                    this.errors = true;
                    this.conflit =
                        qres.confType +
                        " : pas disponible(s) à cette heure";
                } else {
                    this._matDialogRef.close([
                        "save",
                        qres.eventId as number,
                    ]);
                    this.eventAction.setCalendarAction(qres);
                    this.snackBar.openFromComponent(
                        SuccesSnakBarComponent,
                        {
                            duration: 3000,
                        }
                    );
                }
            },
            //! add errors !
            error:(err) => {
                this.savingEvent = false;
                }
            });
        } 
    }
    setAgentResp(val) {

        if (this._data.codeEx) {
            this._data.cands.forEach((elem) => {
                if (elem.id == val.value) {
                    switch (elem.type) {
                        case "Type A":
                        case "Type AA":
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeACodeEx);
                            break;
                        case "Type B":
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeBCodeEx);
                            break;
                        case "Type C":
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeCCodeEx);
                            break;
                        case "Type C+E":
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeCECodeEx);
                            break;
                        case "Type D":
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeDCodeEx);
                            break;
                        case "Type D1":
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeDUnCodeEx);
                            break;
                    }
                }
            });
        }
        if (this._data.condEx) {
            this.setMinDateOnCandChange();

            this._data.cands.forEach((elem) => {
                if (elem.id == val.value) {
                    switch (elem.type) {
                        case "Type A":
                        case "Type AA":
                        
                            this.eventForm
                                .get("frai")
                                .setValue(this._data.tarif.typeACondEx);
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeACodeEx);
                            break;
                        case "Type B":
                            this.eventForm
                                .get("frai")
                                .setValue(this._data.tarif.typeBCondEx);
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeBCodeEx);
                            break;
                        case "Type C":
                            this.eventForm
                                .get("frai")
                                .setValue(this._data.tarif.typeCCondEx);
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeCCodeEx);
                            break;
                        case "Type C+E":
                            this.eventForm
                                .get("frai")
                                .setValue(this._data.tarif.typeCECondEx);
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeCECodeEx);
                            break;
                        case "Type D":
                            this.eventForm
                                .get("frai")
                                .setValue(this._data.tarif.typeDCondEx);
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeDCodeEx);
                            break;
                        case "Type D1":
                            this.eventForm
                                .get("frai")
                                .setValue(this._data.tarif.typeDUnCondEx);
                            this.eventForm
                                .get("renouvellement")
                                .setValue(this._data.tarif.typeDUnCodeEx);
                            break;
                    }
                    if (elem.agentResp != null) {
                        this.eventForm
                            .get("agentResp")
                            .setValue(elem.agentResp);
                        this.setCar();
                    }
                }
            });
        }
        if (this._data.condLess) {
            this._data.cands.forEach((elem) => {
                if (elem.id == val.value) {
                    if (elem.agentResp != null) {
                        this.eventForm
                            .get("agentResp")
                            .setValue(elem.agentResp);
                        this.setCar();
                    }
                }
            });
        }
    }

    setMinDateOnCandChange(){
        if(!this._data.cond_type) this.minDate = this.biggestDate(false);
        else this.minDate = this.biggestDate();
        if(this.minDate > this.eventForm.controls['debut'].value) this.eventForm.controls['debut'].setValue(this.minDate) 
    }

    setTitle(arr) {
        if(this._data.categorie == 'busy'){
            let mapag = new Map();
            this._data.agents?.forEach((elem) => {
                mapag.set(elem.id, elem.username);
            });
            this.eventForm.get("titre")
            .setValue(
                mapag.get(
                    this.eventForm.controls["agentResp"]?.value
                ) + " est occupé(e)"
            );
        }
        else if (arr[1]) {
            let changed = arr[0];
            let mapag = new Map();
            let mapcd = new Map();
            let code = true;
            if (this._data.condEx || this._data.condLess) {
                code = false;
                this._data.agents?.forEach((elem) => {
                    mapag.set(elem.id, elem.username);
                });
            }
            this._data.cands.forEach((elem) => {
                if (
                    elem.id === this.eventForm.controls["candidatId"]?.value &&
                    elem.phase == "code"
                ) {
                    //! needed in displaying the frai
                    this.hasFrai = elem.tentCo > 1;
                }
                mapcd.set(elem.id, elem.username);
            });
            //?  code
            if (
                changed === "candidat" &&
                code &&
                this.eventForm.controls["candidatId"]?.value
            ) {
                this.eventForm
                    .get("titre")
                    .setValue(
                        mapcd.get(this.eventForm.controls["candidatId"]?.value)
                    );
            } 
            //?  !code
            else if (
                changed === "candidat" &&
                !code &&
                this.eventForm.get("agentResp").value != null
            ) {
                let title=""
                if(this._data.agents.length < 2){ 
                    title = mapcd.get(this.eventForm.controls["candidatId"]?.value);
                }
                else {
                    title = mapcd.get(this.eventForm.controls["candidatId"]?.value) +
                " " + mapag.get(
                    this.eventForm.controls["agentResp"]?.value)
                } 
                this.eventForm
                    .get("titre")
                    .setValue(title);
            } else if (
                changed === "agents" &&
                !code &&
                this.eventForm.get("candidatId").value != null
            ) {
                this.eventForm
                    .get("titre")
                    .setValue(
                        mapcd.get(this.eventForm.controls["candidatId"]?.value) +
                        " " +
                        mapag.get(
                            this.eventForm.controls["agentResp"]?.value
                        )
                    );
            }
        }
    }

    setExCondType(candidatId){
        if (this._data.condEx && this._data.action == 'new' && this._data.cond_type != 'parc')
        {
            //? STUDENT CATEGORY IS B || D1
            if(this._data.cands
                .filter(e => (e.type == 'Type D1' || e.type == 'Type B'))
                .map(e=>e.id).indexOf(candidatId) != -1){
                    this._data.cond_type = 'circuit'
                }
            else{
                this._data.cond_type = null;
            }
        }
    }    

    setCar() {
        if (
            (this._data.condLess || this._data.condEx) &&
            this._data.vehicules.length > 1
        ) {
            this._data.agents.forEach((element) => {
                if (
                    element.id == this.eventForm.controls["agentResp"]?.value &&
                    element.vehiculeId !== null
                ) {
                    this.eventForm.controls["vehiculeId"].setValue(
                        element.vehiculeId
                    );
                }
            });
        }
    }
    onFrai() {
        let value = this.eventForm.controls["frai"]?.value;
        if (this.fraiAuDepart != parseInt(value)) {
            this.fraiModifier = true;
            this.differenceFrai = value - this.fraiAuDepart;
        } else {
            this.fraiModifier = false;
        }
    }

    onRenouvellement() {
        let value = this.eventForm.controls["renouvellement"]?.value;
        if (this.renouvAuDepart != parseInt(value)) {
            this.renouvModifier = true;
            this.differenceRenouv = value - this.renouvAuDepart;
        } else {
            this.renouvModifier = false;
        }
    }

    listenToDate(){

    }

    getFiltredCands(){
        if(this._data.categorie == 'busy') return []
        //if we have a parc exam return only the ones that are in phase conduite (taken care of in the event flow service)
        // and has cond-_type parc.
        if(this._data.action == 'new'){
            //! Eligible for parc
            if(this._data.cond_type == 'parc'&& this._data.condEx){
            return this._data.cands.filter((cand)=>{
                    return cand.cond_type == 'parc' && !cand.dateExParc
                })
            }
            //! Eligible for and conduite
            else if(this._data.cond_type != 'parc' && this._data.condEx){
                return this._data.cands.filter((cand)=>{
                
                    return cand.cond_type != 'parc' && !cand.dateExCon 
                            //! Already has a circuit exam
                     //       && !cand.max_circuit
                })
            }
            /* else if(this._data.cond_type == 'circuit' && this._data.condEx){
                return this._data.cands.filter((cand)=>{
                
                    return cand.cond_type != 'parc' && !cand.dateExCon
                })
            } */
            else return this._data.cands.slice()
            }
        else return this._data.cands.slice()
    }

    getMinValEndDate(startDateStringHHmm){
        return moment(this.eventForm.get('tempsDebut').value, "HH:mm")
        .add(15, "minutes")
        .format("HH:mm");
    }

    dateTimeChanged(){
        this.dateTouched = true;

        if(this._data.categorie == 'busy'){
            if(this.fullDay && this.eventForm.get('debut').value.isAfter(this.eventForm.get('fin'))){
                this.eventForm.get('fin').setValue(this.eventForm.get('debut').value)
            } 
            else if(!this.fullDay){
                setTimeout(() => {
                    if(this.eventForm.get('tempsDebut').value >= this.eventForm.get('tempsFin').value){
                        let nv = moment(this.eventForm.get('tempsDebut').value, "HH:mm")
                        .add(60, "minutes")
                        .format("HH:mm");
                        this.eventForm.get('tempsFin').setValue(nv)
                    }
                }, 10);
            }
        }
        //! switch case type then assign date based on jourJ
        else if(this._data.condEx && this._data.action == 'new'){
        switch (this._data.cond_type) {
            case 'parc':
                //! Date less then jourJ and parc. Set it to jourJ Minus One
                if( new Date(this.jourJ) > new Date(this.eventForm.controls["debut"]?.value)){
                    this._data.cond_type = null;
                    //this.eventForm.controls["debut"].setValue(new Date(this.jourJMinusOne));
                }
                break;
            case 'circuit':
                if( new Date(this.jourJ) > new Date(this.eventForm.controls["debut"]?.value)){
                this._data.cond_type = null;
                //this.eventForm.controls["debut"].setValue(new Date(this.jourJMinusOne));
                }

                break;
            case null:
                if( new Date(this.jourJ) <= new Date(this.eventForm.controls["debut"]?.value)){
                    this._data.cond_type = 'circuit';
                    //this.eventForm.controls["debut"].setValue(new Date(this.jourJMinusOne));
                }
                break;

            default:
                break;
            }
            this.filterBanks();
            
            if( !this._data.fromCand && this.eventForm?.controls["candidatId"] 
            && (this.getFiltredCands().map((e)=>{return e.id}).indexOf(this.eventForm.controls["candidatId"]?.value) == -1 ||
            this.biggestDate() > new Date(this.eventForm.controls["debut"]?.value))
            ) {
                this.eventForm.controls["candidatId"].setValue(null);
                this.minDate = null;
            }
            
        }
        
        /* this.newFormat = new Date(this.jourJ) <= new Date(this.eventForm.controls["debut"]?.value)
        if(this.newFormat && this._data.condEx) this._data.cond_type = this._data.cond_type ?? 'circuit';
        else if(!this.newFormat && this._data.condEx) this._data.cond_type = null; */
        //this.setDateAdd();
        
    }

    addLastEvent(){
        if(this.dateTouched){
            let date = moment(this.eventForm.controls["debut"]?.value);
        let d = moment(
            date.format("DD/MM/YYYY") +
            " " +
            this.eventForm.controls["tempsDebut"]?.value,
            "DD/MM/YYYY HH:mm"
        );

        console.log('HJHJ',this.eventForm.controls["one_session_duration_in_minutes"]?.value);
        let d1 = d.clone()
                    .add(
                        this.eventForm.controls["one_session_duration_in_minutes"]?.value
                        *
                        this.eventForm.controls["duration"]?.value,
                        'minutes'
                        );
        if (this.verifNotExist(d, d1)) {
            this.dateTouched = false;
            this.SessionArray.push({
                date: d.clone().startOf("days"),
                debut: d,
                fin: d1,
                confirmed: false,
                absent: false,
                candidatId: this.eventForm.controls["candidatId"]?.value,
                agentResp: this.eventForm.controls["agentResp"]?.value ?? null,
                vehiculeId : (this.eventForm.controls["vehiculeId"]?.value == undefined || this.eventForm.controls["vehiculeId"]?.value == 'client_vehicle') ? null : this.eventForm.controls["vehiculeId"]?.value,
                titre:this.eventForm.controls["titre"]?.value,
                duration: this.eventForm.controls["duration"]?.value,
                one_session_duration_in_minutes:this.eventForm.controls["one_session_duration_in_minutes"]?.value
            });
            console.log('SessionArray',this.SessionArray)
        }
        }
    }

    moduloTrue(num){
        if (num == 0 ) return false
        return num % 2
    }

    addSession() {
        let date = moment(this.eventForm.controls["debut"]?.value);
        let d = moment(
            date.format("DD/MM/YYYY") +
            " " +
            this.eventForm.controls["tempsDebut"]?.value,
            "DD/MM/YYYY HH:mm"
        );
        let d1 = d.clone()
                    .add(this.eventForm.controls["one_session_duration_in_minutes"]?.value
                    *this.eventForm.controls["duration"]?.value,'minutes');
        let agentName = '';
        if(this._data.condLess){
            agentName = (this._data.agents?.length == 1) && this._data.condLess ? '' :
            this._data.agents?.filter(agent=>agent.id == this.eventForm.controls["agentResp"]?.value).map(elem=>" ("+elem.nom[0]+"."+elem.prenom[0]+")")[0]
        }
        if (this.verifNotExist(d, d1)) {
            this.dateTouched = false;
            this.SessionArray.unshift({
                date: d.clone().startOf("days"),
                debut: d,
                fin: d1,
                confirmed: false,
                absent: false,
                duration: this.eventForm.controls["duration"]?.value,
                candidatId: this.eventForm.controls["candidatId"]?.value,
                agentResp: this.eventForm.controls["agentResp"]?.value,
                titre: this.eventForm.controls["titre"]?.value,
                vehiculeId: (this.eventForm.controls["vehiculeId"]?.value == undefined || this.eventForm.controls["vehiculeId"]?.value == 'client_vehicle') ? null : this.eventForm.controls["vehiculeId"]?.value,
                agentName: agentName,
                one_session_duration_in_minutes:this.eventForm.controls["one_session_duration_in_minutes"]?.value
            });
        } else {
            if (this.errorTimeout) clearTimeout(this.errorTimeout);
            this.SessionError = "Séance existante";
            this.errorTimeout = setTimeout(() => {
                this.SessionError = null;
            }, 3000);
        }
        console.log('session array',this.SessionArray);
    }

    openScheduleSettingDialog(){
        this.dialogRefSettings = this._matDialog.open(SchedulingSettingComponent, {
            panelClass: ["common-dialog-style","sc-setting-d-style"],
            data: {
            },
        });
        this.dialogRefSettings
            .afterClosed()
            .pipe(take(1))
            .subscribe(
                (response) => {
                    console.log('res',response);
                    switch (this._data.categorie) {
                        case 'conduiteLess':
                            if(response['less_cond_duration'] != this.eventForm.controls['one_session_duration_in_minutes'].value)
                                    this.setSessionDuration(response['less_cond_duration'])
                            break;
                        case 'codeLess':
                            if(response['less_code_duration'] != this.eventForm.controls['one_session_duration_in_minutes'].value)
                                    this.setSessionDuration(response['less_code_duration'])
                            
                            break;
                        case 'codeEx':
                            if(response['exam_code_duration'] != this.eventForm.controls['one_session_duration_in_minutes'].value)
                                    this.setSessionDuration(response['exam_code_duration'])
                            
                            break;
                        case 'conduiteEx':
                            if(response['exam_cond_duration'] != this.eventForm.controls['one_session_duration_in_minutes'].value)
                                    this.setSessionDuration(response['exam_cond_duration'])
                            
                            break;
                    
                        default:
                            break;
                    }
                    console.log('res',response);
                })
    }

    verifNotExist(d: moment.Moment, d1: moment.Moment) {
        let ok = true;
        try {
            this.SessionArray.forEach((element: any) => {
                if (
                    (d
                        .startOf("minutes")
                        .isSameOrAfter(element.debut.startOf("minutes")) &&
                        d
                            .startOf("minutes")
                            .isBefore(element.fin.startOf("minutes"))) ||
                    (element.debut
                        .startOf("minutes")
                        .isSameOrAfter(d.startOf("minutes")) &&
                        element.debut
                            .startOf("minutes")
                            .isBefore(d1.startOf("minutes")))
                )
                    throw new Error("date is not available");
            });
        } catch (err) {
            ok = false;
        }
        return ok;
    }

    numberOnly(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    deleteSession(i: number) {
        this.SessionArray.splice(i, 1);
    }
    /**
     * Create the event form
     *
     * @returns {FormGroup}
     */
}
