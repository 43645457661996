import { FuseNavigation } from "@fuse/types";

export const navigationagent: FuseNavigation[] = [
    {
        id: "applications",
        title: "",
        type: "group",
        icon: "apps",
        children: [
            {
                id: "dash",
                title: "Acceuil",
                arabic: "(الاستقبال)",
                type: "item",
                icon: "dashboard",
                url: "/dashboard/analytics",
            },
            {
                id: "candidats",
                title: "Candidats",
                arabic: "(المترشحون)",
                type: "item",
                icon: "person",
                url: "/candidats",
            },
            {
                id: "exams-centers",
                title: "Centres d'examen",
                arabic: "",
                type: "item",
                icon: "event_available",
                url: "/list-exams",
                badge: {
                    bg: "#03A9F4",
                    fg: "#ffffff",
                    title: "New"
                },
            },
            {
                id: "online-services",
                title: "Services en ligne",
                arabic: "",
                type: "item",
                icon: "person",
                url: "/online-services/list",
                badge: {
                    bg: "#03A9F4",
                    fg: "#ffffff",
                    title: "New"
                },
            },
            {
                id: "Candidat-Code",
                title: "Suivi en ligne",
                arabic: "",
                type: "collapsable",
                icon: "school",
                children: [
                    {
                        id: "Candidat-Questions",
                        title: "Questions",
                        arabic: "",
                        type: "item",
                        icon: "forum",
                        url: "/code/questions",
                    },
                    {
                        id: "Candidat-series",
                        title: "Series",
                        arabic: "",
                        type: "item",
                        icon: "receipt_long",
                        url: "/code/learning",
                    },
                ],
            },
            {
                id: "ress",
                title: "Résérvations",
                arabic: "(الحجز)",
                type: "collapsable",
                icon: "checklist",
                children: [
                    {
                        id: "Reservation",
                        title: "Séances",
                        arabic: "(حصة)",
                        type: "item",
                        url: "/reservations",
                    },
                    {
                        id: "Reservation",
                        title: "Test",
                        arabic: "(تجربة)",
                        type: "item",
                        url: "/reservations-test",
                    },

                ]
            },
            {
                id: "finance",
                title: "Comptabilité",
                arabic: "(الحسابات)",
                type: "collapsable",
                icon: "attach_money",
                children: [
                    {
                        id: "recettes",
                        title: "Recette",
                        arabic: "(المداخيل)",
                        type: "item",
                        url: "/finance/recettes"
                    },
                    {
                        id: "depenses",
                        title: "Dépenses",
                        arabic: "(المصاريف)",
                        type: "item",
                        url: "/finance/depenses/historique"
                        /* children: [
                            {
                                id: "hDepenses",
                                title: "Historique dépenses",
                                type: "item",
                                url: "/finance/depenses/historique"
                            },
                            {
                                id: "lDepenses",
                                title: "Dépenses",
                                type: "item",
                                url: "/finance/depenses/liste"
                            },
                            {
                                id: "rDepenses",
                                title: "Depenses Réguliéres",
                                type: "item",
                                url: "/finance/depenses/reguliere"
                            }
                        ] */
                    }
                ]
            },
            {
                id: "calendar",
                title: "Calendrier",
                arabic: "(جدول الأوقات)",
                type: "item",
                icon: "today",
                url: "/calendar"
            },
            {
                id: "eqp",
                title: "Administration",
                arabic: "(الإدارة)",
                type: "collapsable",
                icon: "groups",
                function: () => {
                    window.scrollTo(0, document.body.scrollHeight);
                },
                children: [
                    {
                        id: "paramAgence",
                        title: "Mon Ecole",
                        arabic: "(المدرسة)",
                icon: "business",
                type: "item",
                        url: "/administration/agence",
                        exactMatch: true,
                    },
            {
                id: "vhl",
                title: "Véhicules",
                arabic: "(العربات)",
                type: "item",
                icon: "directions_car",
                url: "/ressources/vehicules"
            },
            {
                id: "Ressource et locaux",
                title: "Ressource & Locaux",
                arabic: "(المقر)",
                type: "item",
                url: "/ressources/locaux",
                exactMatch: true
            },
            {
                id: "cmt",
                title: "Mon Compte",
                type: "item",
                arabic: "(الحساب)",
                icon: "person_pin",
                url: "/administration/compte",
                exactMatch: true
            }
        ]
            },
            {
                id: "referral",
                title: "Parrainage",
                arabic: "",
                type: "item",
                icon: "redeem",
                url: "/referral/",
                badge: {
                    bg: "#03A9F4",
                    fg: "#ffffff",
                    title: "New"
                },
                exactMatch: true,
                function: () => {
                    window.scrollTo(0, document.body.scrollHeight);
                }
            },
        ]
    }
];
