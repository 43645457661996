import { FuseNavigation } from "@fuse/types";

export const navigationCodeOnly: FuseNavigation[] = [
    {
        id: "applications",
        title: "",
        type: "group",
        icon: "apps",
        children: [

            {
                id: "candidats",
                title: "Candidats",
                arabic: "(المترشحون)",
                type: "item",
                icon: "person",
                url: "/candidats",
            },
            {
                id: "exams-centers",
                title: "Centres d'examen",
                arabic: "",
                type: "item",
                icon: "event_available",
                url: "/list-exams",
                badge: {
                    bg: "#03A9F4",
                    fg: "#ffffff",
                    title: "New"
                },
            },
            {
                id: "online-services",
                title: "Services en ligne",
                arabic: "",
                type: "item",
                icon: "person",
                url: "/online-services/list",
                badge: {
                    bg: "#03A9F4",
                    fg: "#ffffff",
                    title: "New"
                },
            },

            /*
            {
                id: "Contact",
                title: "Contact",
                arabic: "(الإتصلات)",
                type: "item",
                icon: "contacts",
                url: "/contact",
            }, */
            {
                id: "Candidat-Code",
                title: "Suivi en ligne",
                arabic: "",
                type: "collapsable",
                icon: "school",
                children: [
                    {
                        id: "Candidat-Questions",
                        title: "Questions",
                        arabic: "",
                        type: "item",
                        icon: "forum",
                        url: "/code/questions",
                    },
                    {
                        id: "Candidat-series",
                        title: "Series passées",
                        arabic: "",
                        type: "item",
                        icon: "receipt_long",
                        url: "/code/learning",
                    },
                ],
            },

            {
                id: "administration",
                title: "Administration",
                arabic: "(الإدارة)",
                type: "collapsable",
                icon: "groups",
                function: () => {
                    window.scrollTo(0, document.body.scrollHeight);
                },
                children: [
                    /* {
                        id: "paramAgence",
                        title: "Mon Ecole",
                        arabic: "(المدرسة)",
                        type: "item",
                        icon: "business",
                        url: "/administration/agence",
                        exactMatch: true,
                    }, */
                    {
                        id: "compte",
                        title: "Mon Compte",
                        arabic: "(الحساب)",
                        type: "item",
                        url: "/administration/compte",
                        icon: "person_pin",
                        exactMatch: true,
                    },


                    {
                        id: "listMb",
                        title: "Equipe",
                        arabic: "(فريق العمل)",
                        type: "item",
                        icon: "groups",
                        url: "/administration/equipe",
                        exactMatch: true,
                    },
                    {
                        id: "paiementAgence",
                        title: "Facturation",
                        arabic: "(الفواتير)",
                        icon: "payments",
                        type: "item",
                        url: "/administration/contract",
                        exactMatch: true,
                    },
                ],
            },
            {
                id: "referral",
                title: "Parrainage",
                arabic: "",
                type: "item",
                icon: "redeem",
                url: "/referral/",
                badge: {
                    bg: "#03A9F4",
                    fg: "#ffffff",
                    title: "New"
                },
                exactMatch: true,
                function: () => {
                    window.scrollTo(0, document.body.scrollHeight);
                }
            },
            {
                id: "help",
                title: "Aide",
                arabic: "(مساعدة)",
                type: "item",
                icon: "help",
                url: "/help/knowledge/general-info",
                exactMatch: true,
                /* children: [
                          {
                              id: "base knowledge",
                              title: "Base Knowledge",
                              arabic: "(مساعدة)",
                              type: "item",
                              url: "/help/knowledge",
                              exactMatch: true,
                          },
                          {
                              id: "faq",
                              title: "Faq",
                              arabic: "(مساعدة)",
                              type: "item",
                              url: "/help/faq",
                              exactMatch: true,
                          },
                      ], */
            },
        ],
    },
];
