<div style="position: absolute; opacity: 0;">
    <label for="_email">email</label><input name="_email" type="email">
    <label for="_password">password</label><input name="_password" type="password">
    </div>
<div class="dialog-content-wrapper container">

    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span ngClass="title dialog-title"ngClass.xs="titlefontxs" >Ajouter compte(إضافة حساب)</span>
            <button mat-icon-button (click)="onCancel()"  aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="width px-24 pt-24 pb-12" fusePerfectScrollbar>
        <form [formGroup]="AccountFormUser" autocomplete="off" *ngIf="type=='candidat'">
            <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline">
                <mat-label> Tel login </mat-label>
                <input autocomplete="off" matInput class="input-no-arrow"
                type="number" name="login" required formControlName="username"
                    placeholder="Tel login (لازم)" pattern="^[0-9]{8}$" />
                <mat-error>
                    ce champ est requis
                </mat-error>
            </mat-form-field>
            <br />
            <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline">
                <mat-label>Mot de passe </mat-label>
                <input autocomplete="off" matInput required name="password" [type]="password_type" formControlName="password"
                    placeholder="mot de passe (لازم)"  autofill="off"/>
                    <mat-icon *ngIf="
                    AccountFormUser.controls['password'].value.length !== 0
                        " matSuffix style="cursor: pointer;" (click)="password_icon_click()">{{ password_icon }}
                    </mat-icon>
                <mat-error>
                    ce champ est requis
                </mat-error>
            </mat-form-field>
            <div *ngIf="error" style="background-color: rgb(228, 177, 228); padding: 24px; border-radius: 5px"
                class="register" fxLayout="column" fxLayoutAlign="start start">
                <span class="text">Telephone déja Utilisé</span>

            </div>
        </form>
        <form [formGroup]="AccountFormEquipe" autocomplete="off" *ngIf="type=='equipe'">
            <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline">
                <mat-label> Tel login </mat-label>
                <input autocomplete="off" class="input-no-arrow"
                data-cy="tel-login-member"
                type="number" matInput name="login" required formControlName="email"
                    placeholder="Tel login (لازم)" pattern="^[0-9]{8}$" />
                <mat-error>
                    ce champ est requis
                </mat-error>
            </mat-form-field>
            <br />
            <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline">
                <mat-label>Mot de passe </mat-label>
                <input autocomplete="off" matInput required name="password" [type]="password_type" formControlName="password"
                data-cy="pass-login-member"
                    placeholder="Mot de passe (لازم)"  />
                    <mat-icon *ngIf="
                    AccountFormEquipe.controls['password'].value.length !== 0
                        " matSuffix style="cursor: pointer;" (click)="password_icon_click()">{{ password_icon }}
                    </mat-icon>
                <mat-error>
                    ce champ est requis
                </mat-error>
            </mat-form-field>
            <div *ngIf="error" style="background-color: rgb(228, 177, 228); padding: 24px; border-radius: 5px"
                class="register" fxLayout="column" fxLayoutAlign="start start">
                <span class="text">Telephone déja Utilisé</span>

            </div>
        </form>
    </div>
    <div fxLayoutAlign="end center" fxLayoutGap="4px" class="w-100-p mb-16 px-24" *ngIf="type=='candidat'">
        <button mat-stroked-button (click)="onCancel()">Annuler</button>
        <button mat-raised-button class="mat-accent " [disabled]="AccountFormUser.invalid"
            (click)="addAccount()">
            Enregistrer
        </button>
    </div>
    <div fxLayoutAlign="end center" fxLayoutGap="4px" class="w-100-p mb-16 px-24" *ngIf="type=='equipe'">
        <button mat-stroked-button class="mat-accent" [disabled]="AccountFormEquipe.invalid" data-cy="save-button"
            (click)="addAccountMember()">
            Enregistrer
        </button>
        <button mat-button (click)=" onCancel()">Annuler</button>
    </div>
</div>