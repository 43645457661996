<div class=container>

<div fxLayout="row" fxLayout.xs="column" class="mb-12"fxLayoutAlign.xs="center center" fxLayoutAlign="start start" fxLayoutGap="12px"   > 
  <div fxHide.md="true" class=" close-container" fxHide.lg="true"fxHide.xl=true  fxHide.sm=true fxLayout="row" fxLayoutAlign="end end">
    <button fxHide.md="true" fxHide.lg="true"fxHide.xl=true  fxHide.sm=true class="close-popup" mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div [ngClass]="data ?'restauration-container':'warning-container' ">

    <mat-icon 
    class="material-icons-round warning-sign"
    [ngClass]="data ?'warning-sign-restauration':'warning-sign' "
    >warning_amber
</mat-icon> 
</div>

<div class="main" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="center center">
    <div class="title">{{data?.title ?? 'Confirmer'}}</div>
    <div mat-dialog-content class="content">{{confirmMessage}} </div>

    </div>
    <button fxHide.xs="true" class="close-popup" mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</div>
    
<div class="action" >
<div  mat-dialog-actions dir="rtl"  class="buttons">
    <button mat-raised-button class="mat-accent"   [ngClass]=" data ? 'button-yes-restauration' : 'button-yes' "  (click)="dialogRef.close(true)">{{data?.action ?? 'Confirmer'}}</button>
    <button class="button-no" mat-button (click)="dialogRef.close(false)">{{data?.cancel ?? 'Annuller'}}</button>
</div>
</div>
</div>
