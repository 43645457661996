<!-- PROGRESS BAR -->
<meta
    name="viewport"
    content="initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width"
/>
<link
    rel="stylesheet"
    type="text/css"
    href="//fonts.googleapis.com/css?family=Muli"
/>

<ng-container *ngIf="isLoggedIn; else authView">
    <!-- Elements to display for logged-in users -->
    <fuse-progress-bar></fuse-progress-bar>
    <vertical-layout-1></vertical-layout-1>
</ng-container>

<ng-template #authView>
    <!-- Elements to display for unauthenticated users -->
    <div class="auth-view">
        <router-outlet></router-outlet>
    </div>
</ng-template>


<!-- </ng-container> -->
<!-- / VERTICAL LAYOUT 1 -->

<!-- <div
    class="light-box"
    *ngIf="selectedPicture"
    fxLayout="row"
    fxLayoutAlign="center center"
>
    <div
        class="back"
        (click)="close()"
        fxLayout="row"
        fxLayoutAlign="center center"
    >
        <img
            @FadeInOut
            class="img-container"
            [src]="selectedPicture.src"
            [alt]="selectedPicture.caption"
        />
    </div>
</div>
 -->