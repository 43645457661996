import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErreurService } from 'app/main/authentification/erreur.service';
import { SuccesSnakBarComponent } from 'app/main/components/succes-snak-bar/succes-snak-bar.component';
import { CalendarService } from 'app/main/plus/calendar/calendar.service';

@Component({
  selector: 'app-scheduling-setting',
  templateUrl: './scheduling-setting.component.html',
  styleUrls: ['./scheduling-setting.component.scss']
})
export class SchedulingSettingComponent implements OnInit {

  scheduleSettings: any;
  settingsForm: FormGroup;

  lesson_duration_options = [
    45,
    50,
    55,
    60
  ]

  constructor(
    private _calendarService : CalendarService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public _matDialogRef: MatDialogRef<SchedulingSettingComponent>,
    private _errorService: ErreurService
  ) {
    _matDialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.fetchDate()
  }

  fetchDate(){
    this._calendarService.getScheduleSettings().subscribe({
      next:(res)=>{
        if(res.status == 'fail') return
        this.scheduleSettings = res;
        this.buildForm(res)
      },
      error:(error)=>{}
    })
  }

  buildForm(settings){
    this.settingsForm = this.fb.group({
      less_cond_duration: [settings.less_cond_duration, Validators.required],
      less_code_duration: [settings.less_code_duration, Validators.required],
      exam_code_duration: [settings.exam_code_duration, [Validators.required,Validators.min(30),Validators.max(60)]],
      exam_cond_duration: [settings.exam_cond_duration, [Validators.required,Validators.min(30),Validators.max(60)]],
      seance_confirmation: [settings.seance_confirmation, [Validators.required]],
      seance_confirmation_automatique_delay: [settings.seance_confirmation_automatique_delay, [Validators.required, this.WholeNumberValidator]],
      lesson_reminder_on: [settings.lesson_reminder_on, [Validators.required]],
      reminder_offset_minutes: [settings.reminder_offset_minutes, [Validators.required, this.WholeNumberValidator]],
      daily_reminder_on: [settings.daily_reminder_on, [Validators.required]],
      // transform the date from hh:mm:ss to hh:mm so it can work properly with ngxTimepicker
      reminder_time_inday: [settings.reminder_time_inday?.split(':').slice(0, 2).join(':'), [Validators.required, this.timeValidator]],
      agency_id: settings.agency_id,
    });
  }


  WholeNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value && !/^[+]?[0-9]+$/.test(value)) {
      return { notWholeNumber: true }; // Return an error object for invalid numbers
    }
    return null; // Valid
  }
  
  timeValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value && !/^(?:[01][0-9]|2[0-3]):([0-5][0-9])$/.test(value)) {
      return { notValidTime: true }; // Return an error object for invalid numbers
    }
    return null; // Valid
  }


  onTimeChange(event: any) {
    let timeValue = event.target.value;
    // Check if the entered value matches the valid time format
    const timePattern = /^(?:[01][0-9]|2[0-3]):([0-5][0-9])$/;
    this.settingsForm.get('reminder_time_inday').setErrors(null);
    
    if (timeValue && !timePattern.test(timeValue)) {
      this.settingsForm.get('reminder_time_inday').setErrors({ notValidTime: true });
    }
  }

  onSeanceChange(event) {
    if(event.value === "automatique") {
      if(!this.settingsForm.get("reminder_offset_minutes").value || this.settingsForm.get('reminder_offset_minutes').hasError('notWholeNumber')) {
        this.settingsForm.patchValue({
          reminder_offset_minutes: 15,
        });
      }
      if(!this.settingsForm.get("reminder_time_inday").value || this.settingsForm.get('reminder_time_inday').hasError('notValidTime')) {
        this.settingsForm.patchValue({
          reminder_time_inday: "18:00",
        });
      }
    }
    else if(event.value === "manuelle") {
      if(!this.settingsForm.get("seance_confirmation_automatique_delay").value || this.settingsForm.get('seance_confirmation_automatique_delay').hasError('notWholeNumber')) {
        this.settingsForm.patchValue({
          seance_confirmation_automatique_delay: 15,
        });
      }

    }
  }

  onReminderChange(event) {
    if(!event.value) {
      if(!this.settingsForm.get("reminder_offset_minutes").value || this.settingsForm.get('reminder_offset_minutes').hasError('notWholeNumber')) {
        this.settingsForm.patchValue({
          reminder_offset_minutes: 15,
        });
      }
    }
  }
  
  onDailyReminderChange(event) {
    if(!event.value) {
      if(!this.settingsForm.get("reminder_time_inday").value || this.settingsForm.get('reminder_time_inday').hasError('notValidTime')) {
        this.settingsForm.patchValue({
          reminder_time_inday: "18:00",
        });
      }
    }
  }

  onSubmit(){
    const scheduleSettings = this.settingsForm.getRawValue()
    console.log('scheduleSettings',scheduleSettings);
    this._calendarService.updateScheduleSettings(scheduleSettings)
    .subscribe({
      next: res=>{
        this.snackBar.openFromComponent(SuccesSnakBarComponent, {
          duration: 2000,
        });
        this._matDialogRef.close(scheduleSettings)
      },
      error: err=>{
        console.log('err',err);
        this.settingsForm.markAsPristine()
        this._errorService.popError(err,'Un erreur s\'est produit ! Essayer de nouveau.')
      }
    })
  }

}
